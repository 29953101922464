.customer-search-result {
    padding: 1.2rem 1.6rem;
    outline: none;
    border: none;
    background-color: transparent;
    align-items: flex-start;
    row-gap: 0.4rem;
}
.customer-search-result:hover {
    background-color: var(--color-neptune-100);
    cursor: pointer;
}

.customer-search-result__name,
.customer-search-result__address {
    text-align: left;
}

.customer-search-result__icon {
    flex-shrink: 0;
}
