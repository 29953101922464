.radio-group {
    row-gap: 1.6rem;
}

.radio-group__radio-label {
    &:hover {
        cursor: pointer;
    }

    &--disabled {
        &,
        &:hover,
        & .radio-container {
            cursor: not-allowed;
        }
    }
}

/** @TODO Unify this style with .label-input_asterisk */
.radio-group__asterisk {
    color: var(--color-ocean);
    font-size: var(--text-size-s);
    font-weight: 500;
}
