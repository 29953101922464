@import 'src/ui/mixins/scrollbar';

.date-time-picker {
    min-width: 32rem;
    font-size: var(--text-size-s);
    line-height: 2rem;
    font-weight: var(--font-weight-normal);

    &__header {
        border-bottom: 0.1rem solid var(--color-galaxy-200);
        padding: 1.2rem 1.6rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__body {
        display: flex;
        flex-direction: column;
        overflow: auto;
        row-gap: 0.8rem;
        align-items: center;
        @include thin-scrollbar($scrollbar-width: 0.8rem);
    }

    &__footer {
        border-top: 0.1rem solid var(--color-galaxy-200);
        padding: 0.8rem 1.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__button-close {
        width: auto;
        height: auto;
    }

    &__button-clear {
        padding: 0;
    }

    &__button-apply {
        padding: 0.5rem 1.6rem;
    }
}

.date-time-picker__time-select {
    border-bottom: 0.1rem solid var(--color-galaxy-200);
    padding: 1.2rem 0;
    display: flex;
    flex-direction: column;
    row-gap: 0.8rem;
    width: 100%;
    align-items: center;
}

.date-time-picker__calendar-header {
    height: 4.4rem;
}

.date-time-picker__calendar {
    min-height: 30rem;
    border: none;
    box-shadow: none;
    border-radius: 0;
    display: flex;
    justify-content: center;

    .react-datepicker__month-container,
    .react-datepicker__header,
    .react-datepicker__month,
    .react-datepicker__day-names,
    .react-datepicker__week {
        display: flex;
        gap: 0.8rem;
    }

    .react-datepicker__month-container,
    .react-datepicker__header,
    .react-datepicker__month {
        float: none;
        margin: 0;
        flex-direction: column;
    }

    .react-datepicker__header,
    .react-datepicker__month {
        align-items: center;
    }

    .react-datepicker__day-names,
    .react-datepicker__week {
        width: 24.4rem;
    }

    .react-datepicker__day-name,
    .react-datepicker__day {
        width: 2.8rem;
        font-size: var(--text-size-xs);
        line-height: 2.8rem;
        font-weight: var(--font-weight-normal);
    }

    .react-datepicker__day--outside-month {
        color: var(--color-galaxy-200);
    }
}
