@import 'src/ui/mixins/mapmarker';

.drivermarker {
    @include mapmarker;
}

.drivermarker__circle {
    @include mapmarker-circle(
        $circle-parent-name: drivermarker,
        $circle-size: 4.8rem,
        $circle-hover-border-width: 0.4rem
    );

    font-size: var(--text-size-s);
}

.drivermarker__label {
    @include mapmarker-label($circle-parent-name: drivermarker);
}

.drivermarker__icon {
    bottom: 0;
    right: 0;
}
