.change-task-status-modal-selected-task {
    border: 0.1rem solid var(--color-galaxy-90);
    border-radius: var(--border-radius-l);
    padding: 1.6rem 2.4rem;

    &__select-type {
        background-color: var(--color-comet);
        row-gap: 1rem;
    }

    &__radio-group {
        row-gap: 0.8rem;
    }

    &__radio-group-label,
    &__radio-input-label {
        font-size: var(--text-size-s);
        line-height: 2rem;
    }

    &__radio-group-label,
    &__radio-input-label--checked {
        font-weight: var(--font-weight-medium);
    }

    &__radio-input-group {
        column-gap: 2rem;
    }

    &__radio-label {
        column-gap: 0.8rem;

        &--disabled {
            opacity: 30%;
        }
    }
}
