.duplicate-task-modal-inventory {
    border-radius: var(--border-radius-l);
    background-color: var(--color-comet);
    box-shadow: 0 0.1rem 0.3rem 0 var(--color-box-shadow);
    padding: 1.2rem;
    row-gap: 0.4rem;

    & .routecard-metric__value {
        color: var(--color-galaxy-800-alt);
    }

    &__item-name {
        font-weight: var(--font-weight-medium);
    }

    &__stats {
        column-gap: 1.6rem;
    }
}
