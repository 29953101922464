.routecard-title {
    max-width: 22rem;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--text-size-m);
    font-weight: 500;
    line-height: 2.1rem;
    letter-spacing: var(--letter-spacing-xxs);
    color: var(--color-galaxy-800);
}

.routecard-top_group {
    column-gap: 0.8rem;
}

.completed-stop-popup {
    & .routecard-top_group,
    & .routecard-title {
        max-width: 24rem;
    }
}
