.routecard-metric {
    line-height: 1.4rem;
    font-size: var(--text-size-xs);
    color: var(--color-galaxy-500);

    .icon + .icon {
        margin-left: -0.2rem;
    }

    &--earth {
        color: var(--color-earth);
    }
    &--mars {
        color: var(--color-mars);
    }
    &--venus {
        color: var(--color-venus);
    }
    &--saturn-500 {
        color: var(--color-saturn-500);
    }

    &__values {
        column-gap: 0.2rem;
    }

    &__value--over-capacity,
    &__units--over-capacity {
        color: var(--color-mars-50-alt);
    }
    &__units {
        font-size: var(--text-size-xxs);
    }
}
