.date-time-picker-time-input {
    display: flex;
    column-gap: 1.6rem;
    align-items: center;
    min-width: 24.4rem;
}

.date-time-picker-time-input__label {
    font-size: var(--text-size-xs);
    line-height: 1.6rem;
    min-width: 4rem;
}

.date-time-picker-time-input__input {
    font-size: var(--text-size-s);
    line-height: 2rem;
}

.date-time-picker-time-input__input .timeinput {
    font-size: var(--text-size-s);
    line-height: 2rem;
    padding: 0 0.8rem;
    height: 2.8rem;
    border-radius: var(--border-radius-m);
    background-color: var(--color-galaxy-50);
    width: 100%;
}
