.duplicate-task-modal-collapsible {
    border-radius: var(--border-radius-l);
    background: var(--color-comet);
    box-shadow: 0 0.1rem 0.3rem 0 var(--color-box-shadow);

    &--open {
        row-gap: 0.4rem;
    }

    & &__panel--open {
        max-height: none;
        padding: 0 1.6rem 1.6rem;
    }

    & &__toggle-button {
        padding: 1.6rem;
    }
}

.duplicate-task-modal-collapsible-header {
    column-gap: 1.6rem;

    &__status {
        width: 2.8rem;
        height: 2.8rem;
        border: 0.1rem solid var(--color-galaxy-90);
        border-radius: var(--border-radius-rounded-full);

        &--ready {
            background-color: var(--color-earth-60);
            border-color: var(--color-earth-60);
        }
    }

    &__title {
        font-size: var(--text-size-s);
        font-weight: var(--font-weight-medium);
        line-height: 2rem;
        letter-spacing: var(--letter-spacing-xxxs);
        color: var(--color-galaxy-800-alt);
    }
}
