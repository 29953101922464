.plan-single-stop-control {
    &__control-menu.managestopcontrol_menu {
        background: var(--color-meteor);
    }

    &__menu-button {
        &__button {
            padding: 0 0.6rem;
            background: var(--color-meteor);

            &:hover {
                background: var(--color-meteor);
            }
        }

        &__button-text {
            color: var(--color-white);
        }
    }
}
