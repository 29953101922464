.detail {
    font-size: 1rem;
    width: 100%;
    color: var(--color-galaxy-800);
    column-gap: 0.8rem;
}

.flex-basis-50 {
    flex-basis: 50%;
}

.detail:last-of-type {
    margin-bottom: 0;
}

.detail-content {
    flex-grow: 1;
}

.detail-secondary {
    color: var(--color-galaxy-500);
}

.detail-icon {
    align-self: baseline;
}
