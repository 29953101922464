.stacked-stop-pin-popup {
    max-width: 27.1rem;
    border-radius: var(--border-radius-l);
    z-index: var(--z-index-toast);

    position: absolute;

    &__stacked-stops {
        display: flex;
        gap: 0.2rem;
        align-items: flex-start;

        padding: 0.8rem;
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;

        font-size: var(--text-size-s);
        font-weight: var(--font-weight-medium);
        line-height: 2rem;
        letter-spacing: var(--letter-spacing-xxxs);
        color: var(--color-galaxy-30);
        background-color: var(--color-galaxy-99);
        border-radius: var(--border-radius-l) var(--border-radius-l) 0 0;
        border-bottom: 0.1rem solid var(--color-galaxy-90);

        & .stopmarker {
            transform: none;

            & .stopmarker-body {
                width: 3rem;
                height: 3rem;

                &:hover {
                    cursor: pointer;
                }
            }

            &.stopmarker_selected {
                & .stopmarker-body {
                    border-width: 0.1rem;
                }
            }
        }
    }

    &__stacked-stops--with-labels {
        gap: 1rem;

        .stop-marker-name-and-labels {
            position: initial;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 0;
        }

        .stop-marker-name-and-labels__name,
        .stop-marker-name-and-labels__labels {
            text-wrap: pretty;
            white-space: wrap;
            text-overflow: unset;
            max-width: 12rem;
        }

        .stop-marker-name-and-labels__name {
            font-size: var(--text-size-vxxs);
            text-align: center;
        }

        .stop-marker-name-and-labels__labels {
            font-size: var(--text-size-xxs);
        }
    }
}
