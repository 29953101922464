.managestopcontrol {
    width: 24rem;
    min-height: 10rem;
    padding: 1.2rem;
    background-color: var(--color-comet-alpha-70);
    border-radius: var(--border-radius-l);
    -webkit-backdrop-filter: blur(2.4rem);
    backdrop-filter: blur(2.4rem);
    align-self: flex-start;
}

/*fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .managestopcontrol {
        background-color: rgb(248, 247, 245, 1);
    }
}

.managestopcontrol__view-more {
    color: var(--color-meteor);
    padding: 0;
    font-size: var(--text-size-xs);
}
