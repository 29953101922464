.change-task-status-modal-revise-time-field {
    &__label {
        font-weight: var(--font-weight-medium);
        font-size: var(--text-size-s);
        line-height: 2rem;
    }

    &__dropdown {
        width: 100%;
    }
}
