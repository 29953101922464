.change-task-status-modal-revise-status {
    background-color: var(--color-comet);
    border: 0.1rem solid var(--color-galaxy-90);
    border-radius: var(--border-radius-l);
    padding: 1.6rem 2.4rem;
    row-gap: 1rem;

    &__dropdown,
    &__dropdown-items {
        width: 100%;
    }

    &__dropdown-items,
    .dropdown_outlined {
        border-radius: var(--border-radius-m);
    }

    &__dropdown-item {
        justify-content: space-between;

        &--selected {
            background-color: var(--color-meteor-95-alt);
        }

        &:first-child:hover {
            border-radius: var(--border-radius-m) var(--border-radius-m) 0 0;
        }

        &:last-child:hover {
            border-radius: 0 0var (--border-radius-m) var(--border-radius-m);
        }
    }

    &__label {
        font-weight: var(--font-weight-medium);
        font-size: var(--text-size-s);
        line-height: 2rem;
    }

    &__status-state {
        column-gap: 1rem;
    }

    &__current-status {
        background-color: var(--color-meteor-40);
        border-radius: var(--border-radius-m);
        padding: 0 0.8rem;
        color: var(--color-comet);
        font-size: var(--text-size-vxxs);
        line-height: 1.6rem;
    }
}
