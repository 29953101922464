.date-time-picker-header {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto;
    gap: 0em 0em;
    grid-auto-flow: row;
    grid-template-areas: 'date-time-picker-header__button-decrease date-time-picker-header__title date-time-picker-header__button-increase';
    width: 100%;
    height: 100%;
    align-items: center;
}

.date-time-picker-header__button-decrease {
    grid-area: date-time-picker-header__button-decrease;
    column-gap: 0.8rem;
}

.date-time-picker-header__title {
    grid-area: date-time-picker-header__title;
}

.date-time-picker-header__button-increase {
    grid-area: date-time-picker-header__button-increase;
    column-gap: 0.8rem;
}

.date-time-picker-header__button {
    width: 2.8rem;
    height: 2.8rem;

    &:hover {
        background-color: var(--color-neptune-100);

        svg {
            fill: var(--color-ocean);
        }
    }
}
