@import 'src/ui/mixins/button';

.tooltipbutton {
    column-gap: 0.8rem;
    &:hover {
        cursor: pointer;
    }

    &:disabled {
        &:hover {
            cursor: default;
        }
    }

    &:focus {
        outline: none;
    }
}

.tooltipbutton_l {
    @include button-size(l);
}

.tooltipbutton_m {
    @include button-size(m);
}

.tooltipbutton_s {
    @include button-size(s);
}

.tooltipbutton_primary {
    @include button-variant-primary;
}

.tooltipbutton_secondary {
    @include button-variant-secondary;
}

.tooltipbutton_secondary-alt {
    @include button-variant-secondary(
        $button-color: var(--color-meteor),
        $button-background-color: var(--color-meteor-95-alt),
        $button-border-color: var(--color-meteor-95-alt),
        $button-hover-color: var(--color-comet),
        $button-hover-background-color: var(--color-ocean),
        $button-hover-border-color: var(--color-ocean)
    );
}

.tooltipbutton_link {
    @include button-variant-link;
}

.tooltipbutton_danger {
    @include button-variant-primary(
        $button-background-color: var(--color-mars-500),
        $button-border-color: var(--color-mars-500),
        $button-hover-background-color: var(--color-mars-600),
        $button-hover-border-color: var(--color-mars-600)
    );
}

.tooltipbutton_danger-secondary {
    @include button-variant-secondary(
        $button-color: var(--color-mars-500),
        $button-border-color: var(--color-mars-600),
        $button-hover-color: var(--color-mars-600),
        $button-hover-background-color: var(--color-mars-100),
        $button-hover-border-color: var(--color-mars-600)
    );
}

.tooltipbutton_danger-link {
    @include button-variant-link($button-color: var(--color-mars-500));
}
