.change-task-status-modal-revise-time {
    background-color: var(--color-comet);
    border: 0.1rem solid var(--color-galaxy-90);
    border-radius: var(--border-radius-l);
    padding: 1.6rem 2.4rem;
    row-gap: 1rem;

    &__fields {
        column-gap: 0.8rem;
    }

    .change-task-status-modal-revise-time-field {
        width: 100%;
        max-width: 50%;
    }
}
