.change-task-status-modal-content {
    width: 54rem;

    &__header {
        padding: 1.6rem 2.4rem;
    }

    &__title {
        font-weight: var(--font-weight-medium);
        font-size: var(--text-size-m);
        line-height: 2rem;
    }

    &__body {
        background-color: var(--color-galaxy-99);
        padding: 1.6rem 2rem 2rem;
        row-gap: 1.6rem;
    }

    &__body-section {
        background-color: var(--color-comet);
        border: 0.1rem solid var(--color-galaxy-90);
        border-radius: var(--border-radius-l);
        padding: 1.6rem 2.4rem;
    }

    &__selected-task {
        background-color: transparent;
    }

    &__selected-time {
        column-gap: 0.8rem;
    }

    &__updated-time {
        width: 50%;
        font-weight: var(--font-weight-medium);
        font-size: var(--text-size-s);
        line-height: 2rem;
    }

    &__footer {
        padding: 1.6rem 2.4rem;
        column-gap: 1.2rem;
    }
}
