@import 'src/ui/mixins/alert';
.alert {
    font-size: var(--text-size-xs);
    line-height: 1.6rem;
    border: 0.1rem solid transparent;
    border-radius: var(--border-radius-l);
    padding: 0.8rem 1.2rem;
    column-gap: 0.4rem;
    @include alert-variant;

    // prevent icon component from shrinking
    .icon {
        flex-shrink: 0;
    }

    & + & {
        margin-top: 0.8rem;
    }
}

.alert__has-icon {
    padding-left: 0.8rem;
}

.alert--warning {
    @include alert-variant(
        $alert-background-color: var(--color-venus-100),
        $alert-color: var(--color-venus)
    );
}

.alert--warning-dark {
    @include alert-variant($alert-background-color: var(--color-venus-100));
}

.alert--danger {
    @include alert-variant(
        $alert-background-color: var(--color-mars-100),
        $alert-color: var(--color-mars-500)
    );
}

.alert--danger-dark {
    @include alert-variant($alert-background-color: var(--color-mars-100));
}

.alert--info {
    @include alert-variant($alert-background-color: var(--color-neptune-100));
}
