.duplicate-task-modal-choose-items {
    gap: 0.4rem;

    &__header {
        border-radius: var(--border-radius-l);
        background: var(--color-comet);
        box-shadow: 0 0.1rem 0.3rem 0 var(--color-box-shadow);
        padding: 1.6rem 2.4rem;
    }
    &__title {
        font-size: var(--text-size-s);
        font-weight: var(--font-weight-medium);
        line-height: 2rem;
        letter-spacing: var(--letter-spacing-xxxs);
        color: var(--color-galaxy-800-alt);
    }
    &__description {
        font-size: var(--text-size-xs);
        font-weight: var(--font-weight-normal);
        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-m);
        color: var(--color-galaxy-30);
    }
    &__radio {
        row-gap: 0;
        padding-top: 0.4rem;
        font-size: var(--text-size-m);
        font-weight: var(--font-weight-normal);
        line-height: 2.4rem;

        .radio-group__radio-input-group {
            gap: 1.6rem;
        }
        .radio-group__radio-label {
            gap: 0.8rem;
        }
    }
}
