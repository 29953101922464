.drivermarker-popup {
    width: 20rem;
    position: absolute;
    bottom: 100%;
    padding: 1.4rem;
    background-color: var(--color-comet);
    opacity: 0.9;
    backdrop-filter: blur(10rem);
    border-radius: var(--border-radius-l);
    gap: 0.8rem;

    color: var(--color-galaxy-500);
    font-size: var(--text-size-xs);
    font-weight: var(--font-weight-normal);
    line-height: 1.6rem;
}

.drivermarker-popup__driver {
    color: var(--color-galaxy-800);
    font-weight: var(--font-weight-normal);
    font-size: var(--text-size-s);
    line-height: 2rem;
}

.drivermarker-popup__exceptions,
.drivermarker-popup__current-stop,
.drivermarker-popup__status-time {
    column-gap: 0.8rem;
}
