.preview-stop-card {
    row-gap: 0.2rem;

    &__chain {
        position: relative;
    }

    &__chain-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: var(--z-index-action);
    }
}
.preview-single-stop-card {
    &__route-card {
        width: 100%;
    }

    &__route-marker {
        button {
            width: 2rem;
            height: 2rem;
        }

        span {
            font-size: var(--text-size-xxs);
            font-weight: var(--font-weight-medium);
        }
    }

    &__stop_data {
        row-gap: 0.4rem;
    }

    &__stop-name {
        .routecard-title {
            font-size: var(--text-size-xs);
            line-height: 1.6rem;
        }
    }

    &__route-card--selected {
        &,
        &:hover {
            background-color: var(--color-comet);
        }
    }

    &__route-card--hidden {
        opacity: 30%;
    }
}
