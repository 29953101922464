.duplicate-task-modal-invoice {
    border-radius: var(--border-radius-l);
    border: 0.1rem solid var(--color-galaxy-90);

    background: var(--color-comet);
    font-size: var(--text-size-xs);
    font-weight: var(--font-weight-normal);
    line-height: 1.6rem;
    letter-spacing: var(--letter-spacing-l);
    color: var(--color-galaxy-800);

    &__collapsible {
        padding: 0;

        &__toggle {
            padding: 1.2rem;
            box-shadow: 0 0.1rem 0.3rem 0 var(--color-box-shadow);
            border-radius: var(--border-radius-l);
        }

        & &__toggle-button {
            padding: 0;
        }

        & &__panel {
            padding: 0 0.8rem;
            border-bottom-right-radius: var(--border-radius-l);
            border-bottom-left-radius: var(--border-radius-l);

            &--open {
                padding: 0.8rem;
                background-color: var(--color-galaxy-99);
            }
        }

        &--open {
            .duplicate-task-modal-invoice__collapsible__toggle {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    &__select-invoice {
        column-gap: 1.2rem;
    }

    &__checkbox {
        margin: 0;
        width: 2rem;
    }

    &__invoice {
        font-size: var(--text-size-s);
        font-weight: var(--font-weight-normal);
        line-height: 2rem;
        letter-spacing: var(--letter-spacing-xxxs);
        column-gap: 0.8rem;
    }

    &__units {
        color: var(--color-galaxy-500-alt);
    }

    &--selected {
        background: var(--color-meteor-90);

        .duplicate-task-modal-invoice__units {
            color: var(--color-galaxy-800);
        }

        .duplicate-task-modal-invoice__collapsible {
            &__panel {
                &--open {
                    background-color: var(--color-meteor-95-alt);
                }
            }
        }
    }

    &__labels {
        gap: 0.4rem;
        padding: 0.4rem 0;
        min-height: 2.8rem;
        flex-wrap: wrap;
    }

    &__label {
        padding: 0.2rem 0.8rem;
        border-radius: var(--border-radius-xxxl);
        color: var(--color-galaxy-500-alt);
        font-size: var(--text-size-vxxs);
        font-weight: var(--font-weight-medium);
        line-height: 1.6rem;
        letter-spacing: var(--letter-spacing-l);
        border: 0.1rem solid var(--color-galaxy-90);
    }

    &--selected &__label {
        border: none;
        background: var(--color-meteor);
        color: var(--color-comet);
    }

    &__inventory {
        row-gap: 0.4rem;
    }
}
